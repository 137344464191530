import { showError } from '@/modules/common/helpers/toastHelpers';
import axios, { AxiosInstance, AxiosResponse, HeadersDefaults, InternalAxiosRequestConfig } from 'axios';
import { transformPropertyNames, untransformPropertyNames } from './propNames';


export interface ApiOptions {
  token?: string;
}

export interface ApiError {
  err: number;
  errMsg: string;
}

interface ApiHeaders extends HeadersDefaults {
  Authorization?: string;
}

/**
 * Parse query string into object
 */
export const parseQuery = (queryString: string): Map<string, string> => {
  const query = new Map<string, string>();
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query.set(decodeURIComponent(pair[0]), decodeURIComponent(pair[1] || ''));
  }
  return query
}

const envBaseUrl = '{{KICKBASE_API_URL}}';
let baseUrlInternal: string = !envBaseUrl || envBaseUrl.indexOf('{{') >= 0 ? process.env.VUE_APP_KICKBASE_API : envBaseUrl;
if (window.location.search) {
  const parameters = parseQuery(window.location.search)
  if (parameters.has('apiUrl')) {
    const testElement = document.createElement('a');
    testElement.href = parameters.get('apiUrl') || '';
    if (testElement.hostname === 'localhost' || testElement.hostname.indexOf('.kickbase.com') >= 0) {
      baseUrlInternal = parameters.get('apiUrl') || '';
    }
  }
}

export const baseUrl: string = baseUrlInternal;

export class ApiBase {
  public client: AxiosInstance;
  public token?: string

  /**
   * Construct with options
   */
  constructor(options: ApiOptions = {}) {
    const { token } = options;

    this.client = axios.create({
      baseURL: baseUrl,
      responseType: 'json',
      withCredentials: true,
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
      },
      validateStatus: (status: number) => {
        if (status >= 400) {
          showError('Fehler');
        }
        return status >= 200 && status < 300;
      },
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.client.interceptors.request.use((config: InternalAxiosRequestConfig<any>) => {
      // transform property names
      if (config.data && typeof(config.data) === 'object' && !(config.data instanceof FormData)) {
        config.data = transformPropertyNames(config.data, false);
      }
      return config;
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.client.interceptors.response.use((response: AxiosResponse<any, any>) => {
      if (response.data && typeof(response.data) === 'object') {
        response.data = untransformPropertyNames(response.data, false);
      }
      return response;
    });
  }

  /**
   * Set auth token
   */
  public setToken(token?: string): void {
    this.token = token
    if (!token) {
      delete (this.client.defaults.headers as ApiHeaders).Authorization;
    } else {
      (this.client.defaults.headers as ApiHeaders).Authorization = `Bearer ${token}`;
    }
  }
}

export const api = new ApiBase();
