import { LoginResponse } from './LoginResponse';
import { MeResponse } from './MeResponse';
import { AxiosResponse } from 'axios';

import { api } from '@/api/ApiBase';
import { LoginRequest } from './LoginRequest';

/**
 * Users api.
 */
export class UsersApi {
  /**
   * Login
   */
  public static login(request: LoginRequest): Promise<AxiosResponse<LoginResponse>> {
    return api.client.post('/v4/user/login', request);
  }
  /**
   * Get information about me - the currently logged in user
   */
  public static me(): Promise<AxiosResponse<MeResponse>> {
    return api.client.get('/v4/user/me');
  }
}
